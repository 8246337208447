.App {
  text-align: center;
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#hidden {
  visibility: hidden;
}

.container-div{
  margin: 0 auto;
}
@media only screen and (min-width: 900px) {
  .container-div {
    width: 700px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 900px) {
  #wrapping-div {
    width: 700px;
    margin: 0 auto;
  }
}